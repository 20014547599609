import React from 'react'
import "./footer.scss";
const Footer = () => {
    return (
        <div className='footer'>
            <span>Keto Motors®️</span>
            <span>CDC Battery Dashboard</span>
        </div>
    )
}

export default Footer