import axios from 'axios';
import React, { useEffect, useState } from 'react';
import average from "../assets/Battery/average.png";
import battery from "../assets/Battery/battery.png";
import bpc from "../assets/Battery/bpc.png";
import err from "../assets/Battery/err.png";
import charge from "../assets/Battery/socvector.png";
import "./Notifications.scss";

const Notifications = ({ data }) => {
    const [timestamp, setTimestamp] = useState(data[0]?.createdAt);
    const [iferror, setIfError] = useState([data[0]?.iferror]);
    const [SOC, setSOC] = useState(data[0]?.SOCJump);

    console.log("Notification" + data[0]?.SOCJump);

    const getImageForMessageType = (messageType) => {
        switch (messageType) {
            case 'soc':
            case 'charge':
                return charge;
            case 'capacity':
                return battery;
            case 'cellimbalance':
                return bpc;
            default:
                return average; // Assuming average is the default or for other unspecified message types
        }
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);

        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed
        const year = date.getFullYear().toString().substr(-2); // Get last 2 digits of year

        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');

        return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
    };

    return (
        <div className='Notifications-bar'>
            <h3 className="heading">Notifications</h3>
            {iferror.length > 0 ? (
                iferror.map((error, index) => (
                    <div key={index} className="capsule">
                        <div className="info-img err"> <img src={err} alt="Error" /> </div>
                        <div className="card">
                            <div className="info-head">ERROR</div>
                            <div className="info">
                                {String(error).split(',').map((errMsg, i) => (
                                    <div key={i} className="info-value">{errMsg}</div>
                                ))}
                            </div>
                        </div>
                    </div>
                ))
            ) : (
                <div></div>
            )}
            {SOC ? (
                <div className="capsule">
                    <div className="info-img err"> <img src={charge} alt="SOC Jump" /> </div>
                    <div className="card">
                        <div className="info-head">SOC Jump</div>
                        <div className="info">
                            <div className="info-value">{SOC}</div>
                        </div>
                    </div>
                </div>
            ) : (
                <div></div>
            )}
        </div>
    );
}

export default Notifications;
